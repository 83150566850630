<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-single-02 "></i> Industri
      </h6>
    </h1>
    
    <base-header class="pb-6">
    <b-row>
      <b-col xl="10" md="10">
        <b-card  class="theboxshadow">
          <h5>Cari Berdasarkan</h5>
          <div class="d-flex justify-content-start mb-4">
            <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Jenis Industri" v-model="userInput.jenisIndustri">
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option in provideSelect.companyType"
                    :key="option.uid"
                    :label="option.name"
                    :value="option.uid"
                  ></el-option>
                </el-select>
              </base-input>

              <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Provinsi" v-model="userInput.provinsi" @change="getCityDepentOnProvince(userInput.provinsi)">
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option in provideSelect.provinsi"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  ></el-option>
                </el-select>
              </base-input>

              <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Kab / Kota" v-model="userInput.kabupaten" >
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option, idx in provideSelect.kabupatenKota"
                    :key="idx"
                    :label="option.name "
                    :value="option.id"
                  ></el-option>
                </el-select>
              </base-input>

              <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Nama Industri" v-model="userInput.namaIndustri">
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option in provideSelect.daftarPerusahaan"
                    :key="option.uid"
                    :label="option.name "
                    :value="option.uid"
                  ></el-option>
                </el-select>
              </base-input>

              <div class="col-3">
                <base-button
                  size="md"
                  type="primary"
                  @click="getDataBaseOnFilter"
                >
                  Tampilkan
                </base-button>
              </div>

            </div>
        </b-card>
      </b-col>
    </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            </template>
            <div v-if="isLoading">
              <h1 class="text-center">Loading...</h1>
            </div>
            <div v-else>
              <IndustriTable :tableData="listData"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import API from '../../../api/base_url'
import { Select, Option } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import IndustriTable from '../../Tables/AdminTable/Industri.vue';
import Swal from "sweetalert2";


const flatPickerConfig = {
dateFormat: "U",
allowInput: true,
altInput: true,
altFormat: "d-m-Y H:i",
enableTime: true,
time_24hr: true,
defaultHour: 0,
mode: "range"
};

export default {
components: {
  BaseHeader,
  DataSparingChart,
  flatPicker,
  [Select.name]: Select,
  [Option.name]: Option,
  IndustriTable
},
data() {
  return {
    isLoading:false,
    listData:[],
    provideSelect: {
      flatPickerConfig,
      provinsi:[],
      kabupatenKota:[],
      daftarPerusahaan:[],
      companyType: [],
      tmp:[],
      uploadChoosen:null
    },
    userInput: {
      jenisIndustri:"",
      provinsi:"",
      kabupaten:"",
      namaIndustri:"",
    },
  };
},
mounted() {
  this.getData()
  this.getSelectFormValue();
  this.getCompanyType()
},
methods: {
  getCompanyType() {
    API.get('web/front/registration-company-type')
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
  },
  getData() {
    this.isLoading = true
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`web/master/companies` , {headers})
          .then(({data:content}) => {
              this.listData = content.data
              this.provideSelect.daftarPerusahaan = content.data
              this.isLoading = false
          })
          .catch((err) => {
            console.log(err);
        })
  },
  getDataBaseOnFilter() {
    console.log(this.userInput.jenisIndustri,'jenis industri');
    console.log(this.userInput.provinsi,'provinsi');
    console.log(this.userInput.kabupaten,'kabupaten');
    console.log(this.userInput.namaIndustri,'nama industri');
    Swal.fire('Menampilkan data sesuai pencarian', '', 'success')

    // delete tmp
    this.userInput.jenisIndustri = ""
    this.userInput.provinsi = ""
    this.userInput.kabupaten = ""
    this.userInput.namaIndustri = ""
  },
  handleAdd() {
    console.log("add");
    this.$router.push("/add-company");
  },
  getSelectFormValue() {
      API.get("region/provinces")
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },
  getCityDepentOnProvince(data) {
    this.provideSelect.kabupatenKota = [];
    this.userInput.kabupaten = ""
    API.get(`region/regencies/province/${data}`)
      .then(({ data: content }) => {
        this.provideSelect.kabupatenKota = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
},
};
</script>


<style>
.theboxshadow {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  height: 130px;
}
</style>

  
  